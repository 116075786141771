
import { defineComponent, ref, inject, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Form',
    },
    data: {
      type: Object,
    },
  },
  setup (props) {
    const form = ref(props.data)
    const document = inject<any>('document')
    const allowedExtensions = ref<string>('.pdf,.docx,.xlsx,.csv')
    const store = useStore()

    /* METHODS */
    const handlefile = (e: any) => {
      document.file = e.target.files[0]
    }

    const loading = computed(() => store.getters['PlipReport/status'])

    return {
      form,
      handlefile,
      allowedExtensions,
      loading
    }
  },
})
