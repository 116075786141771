import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: _ctx.title,
    footer: null,
    "destroy-on-close": true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, {
        spinning: _ctx.loading === 'loading'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_alert, {
            message: "Note: Only .docx, .pdf, .csv, and .xlsx are accepted",
            type: "info",
            "show-icon": "",
            style: {"margin-bottom":"10px"}
          }),
          _createVNode(_component_a_form, {
            labelCol: { span: 5 },
            wrapperCol: { span: 18 },
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('save', _ctx.form)), ["prevent"])),
            id: "myForm"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { label: "File" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    type: "file",
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlefile($event))),
                    accept: _ctx.allowedExtensions
                  }, null, 8, ["accept"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { label: "Description" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    type: "text",
                    value: _ctx.form.description,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.description) = $event)),
                    id: "name",
                    required: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { "wrapper-col": { span: 18, offset: 5 } }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        key: "back",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancel')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Cancel ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_button, {
                        key: "submit",
                        type: "primary",
                        htmlType: "submit",
                        loading: _ctx.loading === 'loading'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Submit ")
                        ]),
                        _: 1
                      }, 8, ["loading"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}