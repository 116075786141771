
import Form from '../plip_report/Form.vue'
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed, toRefs, reactive, provide } from 'vue'
import axios from '../../../../axios'
import { Modal } from 'ant-design-vue'
import {appURL} from "@/axios";

export default defineComponent({
  components: {
    Form
  },
  setup () {
    const form = reactive({
      id: '',
      file_name: '',
      file: '',
      description: ''
    })
    provide('document', form)
    const form_action = ref('save')
    const visible = ref(false)
    const store = useStore()
    const report_id = ref('')
    const auth_user = ref(true)
    const isLoading = ref<boolean>(false)

    const fields = ref([
      {
        title: 'Filename',
        dataIndex: 'file_name',
      },
      {
        title: 'Upload Date',
        dataIndex: 'created_at'
      },
      {
        title: 'Action',
        dataIndex: 'plip_report',
        slots: {customRender: 'plip_report'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('PlipReport/index', payload)
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {id, file_name, file, description} = toRefs(form)
      id.value = record.id
      file_name.value = ''
      file.value = ''
      description.value = record.description
      visible.value = true
    }

    const createRecord = () => {
      form_action.value = 'save'
      const {file_name, file, description} = toRefs(form)
      file_name.value = ''
      file.value = ''
      description.value = ''
      visible.value = true
    }

    const deleteRecord = (record: any) => {
      store.dispatch('PlipReport/destroy', record.id)
    }

    const handleSaving = (record: any) => {
      report_id.value = record.id
      let formData = new FormData()

      if (!isFileValid(record)) {
        Modal.error({
          title: 'Error',
          content: 'Invalid file.'
        })
        return
      }

      form_action.value == 'save' ? '' : formData.append('_method', 'PUT')
      formData.append('id', record.id)
      formData.append('description', record.description)
      formData.append('file_name', record.file_name)
      formData.append('file', record.file)

      form_action.value == 'save' ? storeRecord(formData) : updateRecord(formData)
    }

    const isFileValid = (record: any) => {
      if (form_action.value !== 'save' && !record.file) {
        return true
      }

      if (form_action.value === 'save' && !record.file) {
        return false
      }

      const fileName = record.file.name
      const fileExt = fileName.split('.').pop()
      const allowedExtensions = ['.pdf', '.docx', '.xlsx', '.csv']

      return allowedExtensions.includes('.' + fileExt)
    }

    const storeRecord = (payload = {}) => {
      store
          .dispatch('PlipReport/store', payload)
          .then(() => (visible.value = false))
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('PlipReport/update', {id: report_id.value, payload: payload})
          .then(() => (visible.value = false))
    }

    const downloadRecord = (record: any) => {
      const xhr = new XMLHttpRequest()
      let api_url = process.env.VUE_APP_URL
      let download_url = appURL + 'backend/download/plip_reports/' + record.id

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          window.location.href = download_url
          return
        }

        if (xhr.readyState === 4 && xhr.status === 500) {
          Modal.error({
            title: 'Download failed',
            content: 'No PLIP report to be downloaded.',
          })
          return
        }
      }

      xhr.open('head', download_url)
      xhr.send(null)
    }

    const getUser = async () => {
      isLoading.value = true
      const url = 'auth/user'
      const response = await axios.get(url).then((response) => {
        isLoading.value = false
        return response
      })
      const user = response.data.data

      const {role} = toRefs(user)
      role.value = user.role.view
      if (role.value == 'Admin') {
        index()
        auth_user.value = true
      } else {
        auth_user.value = false
      }
    }

    const downloadLatest = (record: any) => {
      const xhr = new XMLHttpRequest()
      let api_url = process.env.VUE_APP_URL
      let download_url = appURL + 'backend/download/plip_reports/latest'

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          window.location.href = download_url
          return
        }

        if (xhr.readyState === 4 && xhr.status === 500) {
          Modal.error({
            title: 'Download failed',
            content: 'No PLIP report to be downloaded.',
          })
          return
        }
      }

      xhr.open('head', download_url)
      xhr.send(null)
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const items = computed(() => store.getters['PlipReport/items'])
    const loading = computed(() => store.getters['PlipReport/status'])

    onMounted(() => {
      getUser()
    })

    return {
      loading,
      items,
      fields,
      visible,
      form,
      report_id,
      auth_user,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord,
      downloadRecord,
      downloadLatest,
      isLoading
    }
  },
})
